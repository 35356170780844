/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import './../../assets/fonts/outfit.ttf';

import '../src/material.css.scss';
import 'header'
import 'footer'

import * as mdc from 'material-components-web';
import { MDCMenu } from '@material/menu';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import {MDCList} from '@material/list';
import {MDCSelect} from '@material/select';
import {MDCSlider} from '@material/slider';
import {MDCSnackbar} from '@material/snackbar';
import {MDCFormField} from '@material/form-field';
import {MDCCheckbox} from '@material/checkbox';


// Select Menus
const select_selector = '.mdc-select';
const select_menus = [].map.call(document.querySelectorAll(select_selector), function(el) {
    return new MDCSelect(el);
});

// Text-fields
const text_field_selector = '.mdc-text-field';
const text_fields = [].map.call(document.querySelectorAll(text_field_selector), function(el) {
    return new MDCTextField(el);
});

//Lists
const list = new MDCList(document.querySelector('.mdc-list'));


// Slider
const slider_init = document.querySelector('.mdc-slider')
if(slider_init != null){
  const slider = new MDCSlider(slider_init);
  // slider.listen('MDCSlider:input', () => valueLayout(displayed_val,'second'));
  slider.foundation_.adapter_.setMarkerValue = function(value){return window.slider.pinValueMarker_.innerText = value.toString()}
  window.slider = slider
}

// Ripples
const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';
const ripples = [].map.call(document.querySelectorAll(selector), function(el) {
    return new MDCRipple(el);
});
const listItemRipples = list.listElements.map((listItemEl) => new MDCRipple(listItemEl));



const checkbox = function (param) { return param ? new MDCCheckbox(param) : null }(document.querySelector('.mdc-checkbox'));
const formField = function (param) { return param ? new MDCFormField(param) : null }(document.querySelector('.mdc-form-field'));
if(formField && checkbox){
  formField.input = checkbox;
}

// Initialize and open Snackbar after the document is done loading
document.onreadystatechange = function() {
    if (document.readyState == "complete") {
        const snackbar_init = document.querySelector('#main-snackbar');
        if(snackbar_init){
          const snackbar = new MDCSnackbar(snackbar_init);
          snackbar.open();
        }
        const error_snackbar_init = document.querySelector('#error-snackbar');
        if(error_snackbar_init){
          const snackbar = new MDCSnackbar(error_snackbar_init);
          snackbar.open();
        }

    }
}

// for accessing mdc components via a window object
// window.mdc = mdc
console.log('Hello World from Webpacker')

//window.mdc.autoInit();
