import { MDCTabBar } from '@material/tab-bar';
import { MDCMenu } from '@material/menu';
import {MDCTopAppBar} from '@material/top-app-bar';

import './styles.css.scss'


document.addEventListener('readystatechange', event => {
  if (event.target.readyState === "complete") {
    initNavMenu()
  }
});

// Initialize the nav tab menus
function initNavMenu() {
  var document_width = document.body.clientWidth; ;
  var mdcSubMenus = [];
  document.querySelectorAll('#nav_bar .mdc-tab[data-menu-id]').forEach(function(btn) {
    var menuDiv = document.getElementById(btn.dataset.menuId);
    var subMenu = MDCMenu.attachTo(menuDiv);
    var enterMenu = false;
    mdcSubMenus.push(subMenu)
    subMenu.open = false;
    btn.addEventListener('mouseenter', (e) => {
      const btn_rect = e.currentTarget.getBoundingClientRect();
      subMenu.setAbsolutePosition(btn_rect.left, btn_rect.bottom);
      subMenu.open = true;
    });
    btn.addEventListener('mouseleave', (e) => {
      setTimeout(function () {
        if (!enterMenu) {
          subMenu.open = false;
        }
      }, 0)
    });
    subMenu.root_.addEventListener('mouseenter', (e) => {
      enterMenu = true
    });
    subMenu.root_.addEventListener('mouseleave', (e) => {
      subMenu.open = false;
      enterMenu = false;
    });
    
    // Nav menu touch screen event
    btn.addEventListener('touchstart', (e) => {
      if (subMenu.open) {
        subMenu.open = false;
      } else {
        const btn_rect = e.currentTarget.getBoundingClientRect();
        subMenu.setAbsolutePosition(btn_rect.left, btn_rect.bottom);
        subMenu.open = true;
        e.preventDefault();
      }
    });
  })
  // navbar respond to windows resize 
  window.addEventListener("resize", function(e) {
    if (document_width == document.body.clientWidth) return false;
    const navBar = document.getElementById('nav_bar');
    document_width = document.body.clientWidth;
    mdcSubMenus.forEach(function(m) {
      m.open = false
    })
    if (window.matchMedia("(max-width: 900px)").matches) {
      navBar.style.display = "none";
    } else {
      navBar.style.display = "-webkit-box";
      navBar.style.display = "-ms-flexbox";
      navBar.style.display = "-webkit-flex";
      navBar.style.display = "flex";
    }
  });
  // On smaller screens - Close submenus first then the navbar
  document.getElementById('main-site-content').addEventListener("click", function(){
    const navBar = document.getElementById('nav_bar');
    if (!window.matchMedia("(max-width: 900px)").matches || !navBar) return;
    
    for (let subMenu of mdcSubMenus) {
      if(subMenu.open === true) return;
    }
    navBar.style.display = "none";
  });
}

///Eventlistener for logout list item of the nav bar
const logout_list_item = document.getElementById("logout-list-item")
if(logout_list_item){
  logout_list_item.addEventListener("click", function(e) {
      var path = e.currentTarget.getAttribute("data-url");
      $.ajax({
        url : path,
        method : 'delete',
        complete: function(data) {
          window.location.reload()
        }
      })
  });
}


const topAppBarElement = document.querySelector('.mdc-top-app-bar');
const topAppBar = new MDCTopAppBar(topAppBarElement);
if(topAppBar){
  topAppBar.setScrollTarget(document.getElementById('main-site-content'));
}


const menuButton = document.getElementById('menu-button');
if(menuButton){
  menuButton.addEventListener("click", function(e) {
      const navBar = document.getElementById('nav_bar');
      if (window.getComputedStyle(navBar).display === "none") {
        navBar.style.display = "flex";
      }else{
        navBar.style.display = "none";
      }
  });
}

